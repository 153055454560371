import { getSafeUTCDateAsStringFromDate } from "modules/helpers/DateHelpers";

const isEmpty = (value) =>
  value === undefined || value === null || value === "";

export function isEmail(value) {
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!isEmpty(value) && !re.test(value)) {
    return false;
  }
  return true;
}

export function isURL(value) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return pattern.test(value);
}

export function isTrue(value) {
  return value === true;
}

export function isPresent(value) {
  if (isEmpty(value)) {
    return false;
  }
  return true;
}

export function isInteger(value) {
  if (!Number.isInteger(Number(value))) {
    return false;
  }
  return true;
}

export function isMinLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return false;
    }
    return true;
  };
}

export function isMaxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return false;
    }
    return true;
  };
}

export function oneOf(enumeration) {
  return (value) => {
    if (!~enumeration.indexOf(value)) {
      return false;
    }
    return true;
  };
}

export function isValidDate(value) {
  if (getSafeUTCDateAsStringFromDate(value) === null) {
    return false;
  }
  return true;
}

export function hasWhiteSpace(value) {
  if (!value) {
    return false;
  }
  const hasWhiteSpace = value.indexOf(" ") >= 0;
  return !hasWhiteSpace;
}
