import * as actionTypes from './actionTypes'
import { generateFieldsForKeys, fieldsAreValid } from 'utils/formHelpers'
import { fieldNames } from './constants'
import { validator } from './validator'

const SETUP_PAYMENT_ACTION_HANDLERS = {
  [actionTypes.UPDATE_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.SET_PAYMENT_METHOD]: (state, action) => {
    return { ...state, paymentMethod: action.paymentMethod }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState()
  },
}

const payloadKeys = {
  number: 'number',
  name: 'name',
  expire: 'expire',
  cvc: 'cvc'
}

function initialState () {
  const fields = generateFieldsForKeys(null, fieldNames, payloadKeys, validator, '')
  return {
    loading: false,
    paymentMethod: null,
    errors: null,
    data: {
      fields: fields,
      isValid: fieldsAreValid(fields)
    }
  }
}

export default function (state = initialState(), action) {
  const handler = SETUP_PAYMENT_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
