import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'

import Woman from 'images/Woman.jpg'

export class HelperText extends Component {

  render () {
    return (
      <div className='lead'>
        <p></p>
        <p>{STRINGS.SetupPayment.HelperText.para1}</p>
        <p>{STRINGS.SetupPayment.HelperText.para2Part1}
          <a
            href='http://support.gymleads.net/pricing-privacy-and-terms/how-does-the-pricing-work-for-gymleads' 
            target='_blank'
            rel='noopener noreferrer'>
          {' ' + STRINGS.SetupPayment.HelperText.para2Part2}
          </a>
        </p>
        <img src={Woman} alt='helper' style={{ height: '120px', marginBottom: '20px' }} />
        <div className='row'>
          <div className='col-md-12'>
            <p className='text-muted'>
              <i className='si si-lock' />{' '}
              {STRINGS.SetupPayment.HelperText.paymentSecurity}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

HelperText.propTypes = {
  user: PropTypes.object.isRequired
}

export default HelperText
