import PropTypes from "prop-types";
import React, { Component } from "react";

export class StepsView extends Component {
  render() {
    const { steps, currentStepIndex } = this.props;
    const stepViews = steps.map((step, index) => {
      const activeClassName =
        index === currentStepIndex ? "active step" : "step";
      return (
        <li key={index} className={activeClassName}>
          <div className="topSubButton" id={index}>
            <i className={step.icon} /> {index + 1 + ". " + step.title}
          </div>
        </li>
      );
    });

    return (
      <div className="block">
        <ul className="nav nav-tabs nav-tabs-alt nav-justified">{stepViews}</ul>
      </div>
    );
  }
}

StepsView.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentStepIndex: PropTypes.number.isRequired,
};

export default StepsView;
