import { request, POST } from 'utils/apiUtils'

export const goToDashboard = () => {
  return (dispatch) => {
    const body = { to: 'ReactApp' }

    function onFailure (payload) {

    }

    function onSuccess (payload) {
      localStorage.clear()
      window.open(payload.url, "_self")
    }

    return request('sso_tokens/', POST, body, onSuccess, onFailure)
  }
}
