import { NAME, SET_COMPANY_STEP, SET_LOCATION_STEP, SET_PAYMENT_STEP } from './constants'
import { createSelector } from 'reselect'

import createCompany from 'containers/createCompany'
import setupLocation from 'containers/setupLocation'
import setupPayment from 'containers/setupPayment'

export const steps = [
  SET_COMPANY_STEP,
  SET_LOCATION_STEP,
  SET_PAYMENT_STEP
]

export const getCurrentStepIndex = state => state[NAME].currentStepIndex
export const getPathName = state => state.routing.location.pathname

export const selectedStep = createSelector(
  [ getCurrentStepIndex ],
  (currentStepIndex) => {
    return steps[currentStepIndex]
  }
)

export const urlStepIndex = createSelector(
  [ getPathName ],
  (pathName) => {
    if (pathName.includes(createCompany.constants.NAME)) {
      return 0
    } else if (pathName.includes(setupLocation.constants.NAME)) {
      return 1
    } else if (pathName.includes(setupPayment.constants.NAME)) {
      return 2
    }
    return -1
  }
)
