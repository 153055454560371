import { request, POST } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import { formDataFromData } from 'utils/formHelpers'
import { fieldNames } from './constants'

import moment from 'moment'
import 'moment-timezone'

import session from 'modules/stores/session'

export const signUp = (formData, whitelabelProfileName) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    let userBody = formDataFromData(formData, fieldNames)
    userBody = { ...userBody, timezone: moment.tz.guess(), user_role_id: 2 }
    const body = {
      user: userBody,
      whitelabel_profile: whitelabelProfileName
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(session.actions.startSession(payload))
    }

    return request('users', POST, body, onSuccess, onFailure)
  }
}

export function updateData (data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}
