import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import HelperText from '../components/HelperText'

import session from 'modules/stores/session'

function mapStateToProps (state) {
  return {
    user: session.selectors.getUser(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({

  },
    dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HelperText))
