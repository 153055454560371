import { isURL, isPresent } from 'utils/validation'
import STRINGS from 'strings'

export const validator = {
  title: [{
    rule: isPresent,
    errorMessage: STRINGS.CreateCompany.Validator.missingCompanyName
  }],
  url: [{
    rule: isURL,
    errorMessage: STRINGS.CreateCompany.Validator.invalidUrl
  }, {
    rule: isPresent,
    errorMessage: STRINGS.CreateCompany.Validator.companyWebsite
  }]
}
