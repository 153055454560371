import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'
import { ContentBlockView } from 'modules/components'

import CreateLocationFormContainer from '../containers/CreateLocationFormContainer'
import HelperTextContainer from '../containers/HelperTextContainer'

export class CreateLocationView extends Component {

  componentWillMount () {
    const { getLocation, getCountries } = this.props
    getLocation()
    getCountries()
  }

  buttonSelected = () => {
    const { location, data, updateLocation } = this.props
    updateLocation(data, location)
  }

  render () {
    const { loading, location, data } = this.props
    const buttonTitle = (location) ? STRINGS.CreateLocation.updateLocation : STRINGS.CreateLocation.createLocation
    return (
      <div className='content content-boxed'>
        <div className='row'>
          <div className='col-md-6'>
            <ContentBlockView
              title={STRINGS.CreateLocation.setupFirstLocation}
              loading={loading}
              bordered>
              <CreateLocationFormContainer />
              <button className='btn btn-success push-5-r push-10'
                style={{ display: 'inline-block' }}
                type='button'
                disabled={!data.isValid}
                onClick={this.buttonSelected}>
                {buttonTitle}
              </button>
            </ContentBlockView>
          </div>
          <div className='col-md-6'>
            <ContentBlockView bordered>
              <HelperTextContainer />
            </ContentBlockView>
          </div>
        </div>
      </div>
    )
  }
}

CreateLocationView.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updateLocation: PropTypes.func.isRequired,
  getLocation: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  location: PropTypes.object
}

export default CreateLocationView
