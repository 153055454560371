import { request, GET, POST, PUT } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import { formDataFromData } from 'utils/formHelpers'
import { fieldNames } from './constants'
import { push } from 'react-router-redux'

export const getCountries = () => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setCountries(payload.countries))
    }

    return request('countries', GET, null, onSuccess, onFailure)
  }
}

export const getLocation = () => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setLocation(payload.locations[0]))
      dispatch(prefilForm(payload.locations[0]))
    }

    return request('locations', GET, null, onSuccess, onFailure)
  }
}

export const updateLocation = (data, location) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    let companyBody = formDataFromData(data, fieldNames)
    const body = {
      location: companyBody
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setLocation(payload.location))
      dispatch(push('/secure/payment'))
    }

    if (location) {
      return request('locations/' + location.id, PUT, body, onSuccess, onFailure)
    } else {
      return request('locations', POST, body, onSuccess, onFailure)
    }
  }
}

export function updateData (data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setLocation(location) {
  return {
    type: actionTypes.SET_LOCATION,
    location
  }
}

export function prefilForm(location) {
  return {
    type: actionTypes.PREFIL_FORM,
    location
  }
}

export function setCountries(countries) {
  return {
    type: actionTypes.SET_COUNTRIES,
    countries
  }
}
