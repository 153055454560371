export const NAME = 'location'

export const fieldNames = [
  'name',
  'addressLineOne',
  'addressLineTwo',
  'city',
  'state',
  'phoneNumber',
  'countryId',
  'zipPostcode',
  'timezone'
]
