import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class Checkbox extends Component {

  state = {
    errorMessage: null
  }

  handleChange = (event) => {
    const switchValue = event.target.checked
    let style = ''
    let errorMessage = null

    this.setState({ ...this.state, errorMessage: errorMessage, 'style': style })
    this.props.onChange(this.props.name, switchValue)
  }

  render () {
    const { name, disabled, switchStyle, label, checked } = this.props
    return (
      <label className={'css-input css-checkbox css-checkbox-' + switchStyle}
        style={{ display: 'table' }}htmlFor={name}>
        <input
          type='checkbox'
          id={name}
          name={name}
          onChange={this.handleChange}
          disabled={disabled}
          checked={checked}
          style={{ display: 'table-cell' }}
          />
        <span />
        <span style={{ display: 'table-cell' }}>{' '}{label}</span>
      </label>
    )
  }
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  switchStyle: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool
}

Checkbox.defaultProps = {
  disabled: false
}
