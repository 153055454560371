import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'

import Woman from 'images/Woman.jpg'

export class HelperText extends Component {

  render () {
    const { user } = this.props
    return (
      <div className='lead'>
        <p>{STRINGS.Global.hey} {user.given_name}!</p>
        <p>{STRINGS.CreateCompany.HelperText.intro}</p>
        <p>{STRINGS.CreateCompany.HelperText.para1}</p>
        <p>{STRINGS.CreateCompany.HelperText.para2}</p>
        <p>{STRINGS.CreateCompany.HelperText.para3}</p>
        <p>{STRINGS.CreateCompany.HelperText.para4}</p>
        <img src={Woman} alt='helper' style={{ height: '120px', marginBottom: '20px' }} />
      </div>
    )
  }
}

HelperText.propTypes = {
  user: PropTypes.object.isRequired
}

export default HelperText
