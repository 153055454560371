import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView } from 'modules/components'
import STRINGS from 'strings'
import Woman from 'images/Woman.jpg'

export class CompleteView extends Component {

  goToDashboardClicked = () => {
    this.props.goToDashboard()
  }

  render () {
    const { user } = this.props
    return (
      <div className='content content-boxed'>
        <ContentBlockView>
          <div className='row'>
            <div className='col-md-2'>
              <img src={Woman} alt='helper' style={{ height: '200px', marginBottom: '20px' }} />
            </div>
            <div className='col-md-10'>
              <div className='lead'>
                <p>{STRINGS.Complete.finishedSetup} {user.given_name}!</p>
                <p>{STRINGS.Complete.headToDashboard}</p>
              </div>
              <button className='btn btn-primary push-5-r push-10'
                style={{ display: 'inline-block' }}
                type='button'
                onClick={this.goToDashboardClicked}>
                {STRINGS.Complete.goToDashboardBtn} <i className='si si-check' />
              </button>
            </div>
          </div>
        </ContentBlockView>
      </div>
    )
  }
}

CompleteView.propTypes = {
  user: PropTypes.object.isRequired,
  goToDashboard: PropTypes.func.isRequired
}

export default CompleteView
