import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import CreateCompanyView from '../components/CreateCompanyView'

import * as selectors from '../selectors'
import * as actions from '../actions'
import * as userDataSelectors from 'modules/stores/userData/selectors'

function mapStateToProps (state) {
  return {
    data: selectors.getData(state),
    company: selectors.getCompany(state),
    loading: selectors.getLoading(state),
    whitelabelProfile: userDataSelectors.getWhitelabelProfile(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getCompany: actions.getCompany,
    updateCompany: actions.updateCompany
  },
    dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateCompanyView))
