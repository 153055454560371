import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import SignupView from '../components/SignupView'
import * as selectors from '../selectors'
import * as actions from '../actions'
import { fetchWhitelabelProfileLogo } from 'modules/stores/userData/actions'
import { getWhitelabelProfile, getProductName } from 'modules/stores/userData/selectors'

function mapStateToProps (state) {
  return {
    loading: selectors.getLoading(state),
    errors: selectors.getErrors(state),
    data: selectors.getData(state),
    clientID: new URLSearchParams(window.location.search).get("client"),
    productName: getProductName(state),
    whitelabelProfile: getWhitelabelProfile(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    signUp: actions.signUp,
    fetchWhitelabelProfileLogo: fetchWhitelabelProfileLogo
  },
    dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupView))
