import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'

import Woman from 'images/Woman.jpg'

export class HelperText extends Component {

  render () {
    return (
      <div className='lead'>
        <p>{STRINGS.CreateLocation.HelperText.intro}</p>
        <p>{STRINGS.CreateLocation.HelperText.para1}</p>
        <p>{`${STRINGS.CreateLocation.HelperText.para2Part1} ${this.props.productName} ${STRINGS.CreateLocation.HelperText.para2Part2}`}.</p>
        <img src={Woman} alt='helper' style={{ height: '120px', marginBottom: '20px' }} />
      </div>
    )
  }
}

HelperText.propTypes = {
  user: PropTypes.object.isRequired,
  productName: PropTypes.string
}

export default HelperText
