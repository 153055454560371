import PropTypes from 'prop-types'
import React, { Component } from 'react'
import STRINGS from 'strings'
import { ContentBlockView } from 'modules/components'

import CreateCompanyFormContainer from '../containers/CreateCompanyFormContainer'
import HelperTextContainer from '../containers/HelperTextContainer'

export class CreateCompanyView extends Component {

  componentWillMount () {
    const { getCompany } = this.props
    getCompany()
  }

  buttonSelected = () => {
    const { company, data, updateCompany, whitelabelProfile } = this.props
    updateCompany(data, company, whitelabelProfile.safe_name)
  }

  render () {
    const { loading, company, data } = this.props
    const buttonTitle = (company) ? STRINGS.CreateCompany.updateCompany : STRINGS.CreateCompany.setUpCompany
    return (
      <div className='content content-boxed'>
        <div className='row'>
          <div className='col-md-6'>
            <ContentBlockView
              title={STRINGS.CreateCompany.setUpCompany}
              loading={loading}
              bordered>
              <CreateCompanyFormContainer />
              <button className='btn btn-success push-5-r push-10'
                style={{ display: 'inline-block' }}
                type='button'
                disabled={!data.isValid}
                onClick={this.buttonSelected}>
                {buttonTitle}
              </button>
            </ContentBlockView>
          </div>
          <div className='col-md-6'>
            <ContentBlockView bordered>
              <HelperTextContainer />
            </ContentBlockView>
          </div>
        </div>
      </div>
    )
  }
}

CreateCompanyView.propTypes = {
  getCompany: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updateCompany: PropTypes.func.isRequired,
  company: PropTypes.object
}

export default CreateCompanyView
