import * as actionTypes from './actionTypes'
import { generateFieldsForKeys, fieldsAreValid } from 'utils/formHelpers'
import { fieldNames } from './constants'
import { validator } from './validator'

const SIGNUP_ACTION_HANDLERS = {
  [actionTypes.UPDATE_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState()
  },
}

const payloadKeys = {
  givenName: 'given_name',
  surname: 'surname',
  email: 'email',
  password: 'password',
  username: 'username',
  userRoleId: 'user_role_id',
  agreeToTerms: 'agreeToTerms'
}

function initialState () {
  const fields = generateFieldsForKeys(null, fieldNames, payloadKeys, validator)
  return {
    loading: false,
    errors: null,
    data: {
      fields: fields,
      isValid: fieldsAreValid(fields)
    }
  }
}

export default function (state = initialState(), action) {
  const handler = SIGNUP_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
