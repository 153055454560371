import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Modal from '@atlaskit/modal-dialog';
import 'styles/modalStyles.css'

export class ModalView extends Component {

  render () {
    const { currentModal, hideModal, modalName, showCancelButton, actionButtonElement, modalTitle } = this.props
    const theme = this.props.colorThemeClass || 'primary'

    const footer = () =>
      <div className='modal-footer'>
        {showCancelButton &&
          <button className='btn btn-default push-5-r push-10' type='button' onClick={hideModal}>
            Cancel
          </button>
        }
        {actionButtonElement &&
          <button className={'btn btn-' + theme + ' push-5-r push-10'}
            type='button'
            disabled={!this.props.actionEnabled}
            onClick={this.props.actionButtonClicked}>
            {actionButtonElement}
          </button>
        }
      </div>

    return (
      <div>
        {currentModal === modalName &&
          <Modal
            onClose={hideModal}
            footer={footer}
            header={null}
            heading={modalTitle}>
              {this.props.children}
            </Modal>
        }
      </div>
    )
  }
}

ModalView.propTypes = {
  colorThemeClass: PropTypes.string,
  // loading: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ]),
  actionEnabled: PropTypes.bool,
  actionButtonElement: PropTypes.element,
  actionButtonClicked: PropTypes.func,
  showCancelButton: PropTypes.bool
}

export default ModalView
