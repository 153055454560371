import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContentBlockView } from 'modules/components'
import STRINGS from 'strings'

import SetupPaymentFormContainer from '../containers/SetupPaymentFormContainer'
import HelperTextContainer from '../containers/HelperTextContainer'

export class SetupPaymentView extends Component {

  componentWillMount () {
    const { getPaymentMethod } = this.props
    getPaymentMethod()
  }

  buttonSelected = () => {
    const { data, createPaymentMethod } = this.props
    createPaymentMethod(data)
  }

  goToDone = () => {
    this.props.completeSetup()
  }

  render () {
    const { loading, paymentMethod, data } = this.props
    return (
      <div className='content content-boxed'>
        <div className='row'>
          <div className='col-md-6'>
            <ContentBlockView
              title={STRINGS.SetupPayment.setupPaymentDetails}
              loading={loading}
              bordered>
              {!paymentMethod &&
                <div>
                  <SetupPaymentFormContainer />
                  <button className='btn btn-success push-5-r push-10'
                    style={{ display: 'inline-block' }}
                    type='button'
                    disabled={!data.isValid}
                    onClick={this.buttonSelected}>
                    {STRINGS.SetupPayment.setPaymentMethod}
                  </button>
                </div>
              }
              {paymentMethod &&
                <div>
                  <p className='lead'>
                  {STRINGS.SetupPayment.paymentMethodAlreadySet}
                  </p>
                  <button className='btn btn-success push-5-r push-10'
                    style={{ display: 'inline-block' }}
                    type='button'
                    onClick={this.goToDone}>
                    {STRINGS.SetupPayment.completeSetupBtn}
                  </button>
                </div>
              }
            </ContentBlockView>
          </div>
          <div className='col-md-6'>
            <ContentBlockView bordered>
              <HelperTextContainer />
            </ContentBlockView>
          </div>
        </div>
      </div>
    )
  }
}

SetupPaymentView.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  paymentMethod: PropTypes.object,
  createPaymentMethod: PropTypes.func.isRequired,
  getPaymentMethod: PropTypes.func.isRequired,
  completeSetup: PropTypes.func.isRequired,
  location: PropTypes.object
}

export default SetupPaymentView
