import { request, GET, POST, PUT } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import { formDataFromData } from 'utils/formHelpers'
import { fieldNames } from './constants'
import { push } from 'react-router-redux'

export const getCompany = () => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setCompany(payload.company))
      dispatch(prefilForm(payload.company))
    }

    return request('user_company', GET, null, onSuccess, onFailure)
  }
}

export const updateCompany = (data, company, whitelabelProfileName) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    let companyBody = generatSantizedCompanyJSON(formDataFromData(data, fieldNames))
    companyBody.whitelabel_profile = whitelabelProfileName
    const body = { company: companyBody }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setCompany(payload.company))
      dispatch(push('/secure/location'))
    }

    if (company) {
      return request('companies/' + company.id, PUT, body, onSuccess, onFailure)
    } else {
      return request('companies', POST, body, onSuccess, onFailure)
    }
  }
}

function generatSantizedCompanyJSON (company) {
  if (!/^https?:\/\//i.test(company.url)) {
    return { ...company, url: 'http://' + company.url }
  }
  return company
}

export function updateData (data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setCompany(company) {
  return {
    type: actionTypes.SET_COMPANY,
    company
  }
}

export function prefilForm(company) {
  return {
    type: actionTypes.PREFIL_FORM,
    company
  }
}
