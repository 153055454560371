import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import CompleteView from '../components/CompleteView'

import session from 'modules/stores/session'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    user: session.selectors.getUser(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    goToDashboard: actions.goToDashboard
  },
    dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompleteView))
