import * as actionTypes from './actionTypes'

const USER_DATA_ACTION_HANDLERS = {
  [actionTypes.LOADING_STARTED]: (state, action) => {
    return { ...state, loading: true }
  },
  [actionTypes.LOADING_STOPPED]: (state, action) => {
    return { ...state, loading: false }
  },
  [actionTypes.COMPANY_DATA_FETCH_SUCESS]: (state, action) => {
    return { ...state, company: action.company }
  },
  [actionTypes.LOCATIONS_FETCH_SUCCESS]: (state, action) => {
    return { ...state, locations: action.locations }
},
[actionTypes.WHITELABEL_PROFILE]: (state, action) => {
  return { ...state, whitelabelProfile: action.whitelabelProfile.whitelabel_profile }
  },
  [actionTypes.USER_ROLES_FETCH_SUCCESS]: (state, action) => {
    return { ...state, userRoles: action.userRoles }
  }
}

const initialState = {
  loading: false,
  locations: [],
  company: {},
  whitelabelProfile: {}
}

export default function reducer (state = initialState, action) {
  const handler = USER_DATA_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
