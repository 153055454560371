export const NAME = 'signUp'

export const fieldNames = [
  'givenName',
  'surname',
  'email',
  'password',
  'username',
  'userRoleId',
  'agreeToTerms'
]
