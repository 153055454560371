import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Header from './Header'

import { getWhitelabelProfile } from 'modules/stores/userData/selectors'

import session from 'modules/stores/session'
import alert from 'modules/components/alert'

const mapStateToProps = (state) => {
  return {
    user: session.selectors.getUser(state),
    whitelabelProfile: getWhitelabelProfile(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showAlertWithConfig: alert.actions.showWithConfig,
    logout: session.actions.logout,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
