import PropTypes from "prop-types";
import React, { Component } from "react";
import "./Header.css";
import logo from "resources/logo.png";
import alert from "modules/components/alert";
import STRINGS from "strings";

export class Header extends Component {
  showLogoutWarning = () => {
    const { showAlertWithConfig } = this.props;
    const alertConfig = {
      ...alert.defaultConfig,
      children: STRINGS.Header.finishLaterWarning,
      style: "warning",
      onConfirm: this.confirmLogout,
    };
    showAlertWithConfig(alertConfig);
  };

  confirmLogout = () => {
    const { whitelabelProfile } = this.props;
    const safeName =
      whitelabelProfile && whitelabelProfile.safe_name
        ? whitelabelProfile.safe_name
        : null;
    this.props.logout(safeName);
  };

  setLogo() {
    const { whitelabelProfile } = this.props;
    if (whitelabelProfile && whitelabelProfile.logo_url) {
      this.setDocumentTitle(whitelabelProfile.name);
      return whitelabelProfile.logo_url;
    } else {
      this.setDocumentTitle("GymLeads");
      return logo;
    }
  }

  setDocumentTitle(name) {
    document.title = `${name} | ${STRINGS.Global.documentTitle}`;
  }

  render() {
    const { user } = this.props;
    return (
      <header id="header-navbar">
        <div className="content-mini content-mini-full">
          {user && (
            <ul className="nav-header pull-right">
              <li>
                <button
                  className="linkButton"
                  onClick={this.showLogoutWarning}
                  style={{
                    padding: "0 12px",
                    display: "block",
                    height: "34px",
                    lineHeight: "34px",
                    fontWeight: "600",
                    color: "#5c90d2",
                  }}
                >
                  {STRINGS.Header.finishLaterBtn}
                </button>
              </li>
            </ul>
          )}

          <ul className="nav-header pull-left">
            <li>
              <img src={this.setLogo()} alt="logo" style={{ width: "120px" }} />
            </li>
            <li className="push-5-t">{STRINGS.Header.accountSetup}</li>
            {user && (
              <li className="push-5-t">
                {STRINGS.Header.loggedInAs} {user.display_name}
              </li>
            )}
          </ul>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  user: PropTypes.object,
  showAlertWithConfig: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default Header;
