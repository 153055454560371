import PropTypes from 'prop-types'
import React, { Component } from 'react'
import AlertContainer from 'modules/components/alert/containers/AlertContainer'
import HeaderContainer from 'modules/components/header/HeaderContainer'
import * as analyticActions from 'modules/helpers/Analytics'

import { PrivateRouteContainer } from 'modules/components'

import steps from 'containers/steps'
import createCompany from 'containers/createCompany'
import setupLocation from 'containers/setupLocation'
import setupPayment from 'containers/setupPayment'
import complete from 'containers/complete'

export class SecureView extends Component {

  componentWillMount () {
    analyticActions.startUserSession()
  }

  render () {
    const { loadingComplete } = this.props
    return (
      <div id='page-container' className='header-navbar-fixed'>
        <HeaderContainer />
        <main id='main-container' style={{ minHeight: '921px' }}>
          {loadingComplete === true &&
            <div>
              <div className='row push-20-t'>
                <div className='col-md-10 col-md-offset-1'>
                  <steps.StepsContainer />
                </div>
              </div>

              <PrivateRouteContainer exact path={"/secure/" + createCompany.constants.NAME} component={createCompany.CreateCompanyContainer} />
              <PrivateRouteContainer exact path={"/secure/" + setupLocation.constants.NAME} component={setupLocation.CreateLocationContainer} />
              <PrivateRouteContainer exact path={"/secure/" + setupPayment.constants.NAME} component={setupPayment.SetupPaymentMethodContainer} />
              <PrivateRouteContainer exact path={"/secure/" + complete.constants.NAME} component={complete.CompleteContainer} />
              <AlertContainer />
            </div>
          }
          {!loadingComplete &&
            <div id='page-loader' style={{ display: 'block' }} />
          }
        </main>
      </div>
    )
  }
}

SecureView.propTypes = {
  loadingComplete: PropTypes.bool.isRequired,
  loadInitialStateForCurrentUser: PropTypes.func.isRequired,
  clearAttemptedUrl: PropTypes.func.isRequired
}

export default SecureView
