import config from "config";
import PropTypes from "prop-types";
import React, { Component } from "react";
import SignupFormContainer from "../containers/SignupFormContainer";
import { errorPanelForErrors } from "utils/formHelpers";
import logo from "resources/logo.png";
import STRINGS from "strings";

export class SignupView extends Component {
  componentDidMount() {
    this.lookForWhitelabelProfile(this.props.clientID);
  }

  lookForWhitelabelProfile(clientID) {
    const { fetchWhitelabelProfileLogo } = this.props;
    if (clientID) {
      fetchWhitelabelProfileLogo(clientID);
    }
  }

  setLogo() {
    const { whitelabelProfile, clientID } = this.props;

    if (!clientID) {
      this.setDocumentTitle("GymLeads");
      return logo;
    } else if (whitelabelProfile && whitelabelProfile.logo_url) {
      this.setDocumentTitle(whitelabelProfile.name);
      return whitelabelProfile.logo_url;
    } else if (whitelabelProfile && whitelabelProfile.name === "Gymleads") {
      this.setDocumentTitle("GymLeads");
      return logo;
    }
  }

  setDocumentTitle(name) {
    document.title = `${name} | ${STRINGS.Global.documentTitle}`;
  }

  handleSubmit = (event) => {
    event && event.preventDefault();
    const { data, signUp, whitelabelProfile } = this.props;
    if (!data.isValid) {
      return;
    }
    signUp(data, whitelabelProfile.safe_name);
  };

  buttonDisabled = () => {
    const { data, loading } = this.props;
    if (data.isValid === false) {
      return true;
    }
    return loading;
  };

  includeClientID = () => {
    const { clientID } = this.props;
    return clientID ? `?client=${clientID}` : "";
  };

  setCopyright() {
    if (this.props.clientID) {
      return (
        <div>
          <span className="js-year-copy">{STRINGS.Global.copyrightDate}</span>{" "}
          {STRINGS.Global.poweredByGymleads}
        </div>
      );
    } else {
      return (
        <div>
          <span className="js-year-copy">{STRINGS.Global.copyrightDate}</span>{" "}
          {STRINGS.Global.copyrightText}
        </div>
      );
    }
  }

  render() {
    const { errors, loading, productName } = this.props;
    const loginSymbol = loading
      ? "fa fa-circle-o-notch fa-spin pull-right"
      : "si si-user-follow";
    const buttonTitle = loading
      ? STRINGS.Signup.creatingAccount
      : STRINGS.Signup.createAccount;
    const logoImage = this.setLogo();

    return (
      <div>
        <div className="bg-white pulldown">
          <div className="content context-boxed overflow-hidden">
            <div className="row">
              <div className="col-md-12 text-center push-30-t">
                {logoImage && (
                  <img
                    src={this.setLogo()}
                    alt="logo"
                    style={{ height: "80px", paddingBottom: "10px" }}
                  />
                )}
                <p className="animated fadeIn" style={{ margin: "10px" }}>
                  {STRINGS.Signup.createAnAccount}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-md-offset-2">
                <div className="push-30-t push-50 animated fadeIn">
                  {errorPanelForErrors(errors, true)}
                  <SignupFormContainer />
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <button
                        type="submit"
                        className="btn btn-block btn-success"
                        disabled={this.buttonDisabled()}
                        onClick={this.handleSubmit}
                      >
                        <i className={loginSymbol} /> {buttonTitle}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="push-30-t push-50 animated fadeIn">
                  <div className="block block-rounded block-bordered">
                    <div className="block-content" style={{ height: "250px" }}>
                      <h3>{STRINGS.Signup.createYourAccount}</h3>
                      <br />
                      <p>
                        {STRINGS.Signup.enterDetails} {productName}.
                      </p>
                      <p>{STRINGS.Signup.signupDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p className="push-50 text-center">
                  <a href={config.mainHost + "/login" + this.includeClientID()}>
                    {STRINGS.Global.login}
                  </a>
                  {` ${STRINGS.Global.or} `}
                  <a
                    href={
                      config.mainHost +
                      "/forgotPassword" +
                      this.includeClientID()
                    }
                  >
                    {STRINGS.Global.resetPassword}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="pulldown push-30-t text-center animated fadeInUp">
          <small className="text-muted">{this.setCopyright()}</small>
        </div>
      </div>
    );
  }
}

SignupView.propTypes = {
  loading: PropTypes.bool.isRequired,
  signUp: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default SignupView;
