import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { validator } from '../validator'
import { generateNewFormState } from 'utils/formHelpers'
import { FormFields } from 'modules/components'
import STRINGS from 'strings'

const { TextField, PasswordField, Checkbox } = FormFields

export class SignupForm extends Component {

  handleInput = (fieldName, value, isValid) => {
    const { data, updateData } = this.props
    const newData = generateNewFormState(data, fieldName, value, isValid)
    updateData(newData)
  }

  handleToggle = (fieldName, value, isValid) => {
    const { data, updateData } = this.props
    const newData = generateNewFormState(data, fieldName, value, value)
    updateData(newData)
  }

  render () {
    return (
      <form className='form-horizontal push-30-t'>
        <div className='row'>
          <div className='col-md-6'>
            <TextField
              name='givenName'
              label={STRINGS.Signup.firstName}
              placeholder='Joanne'
              formFieldStyle='form-material form-material-success'
              onChange={this.handleInput}
              validator={validator}
              usePadding />
          </div>
          <div className='col-md-6'>
            <TextField
              name='surname'
              label={STRINGS.Signup.lastName}
              placeholder='Jones'
              formFieldStyle='form-material form-material-success'
              onChange={this.handleInput}
              validator={validator} />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <TextField name='email'
              label={STRINGS.Signup.email}
              placeholder='joane@yourawesomeclub.com.au'
              formFieldStyle='form-material form-material-success'
              onChange={this.handleInput}
              validator={validator}
              usePadding />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <TextField
              name='username'
              label={STRINGS.Signup.username}
              placeholder='A unique username'
              formFieldStyle='form-material form-material-success'
              onChange={this.handleInput}
              validator={validator}
              usePadding />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <PasswordField
              name='password'
              label={STRINGS.Signup.password}
              placeholder='Secure Password'
              formFieldStyle='form-material form-material-success'
              onChange={this.handleInput}
              validator={validator} />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-1'>
            <Checkbox
              name='agreeToTerms'
              label=''
              onChange={this.handleToggle}
              switchStyle='success'
              />
          </div>
          <div className='col-xs-11'>
            <p className='push-10-l' style={{ marginTop: '5px' }}>
              {STRINGS.Signup.iAgree}{' '}<a href='https://www.gymleads.net/termsofuse' target='_blank' rel='noopener noreferrer'>
              {STRINGS.Signup.termsAndConditions}</a> {STRINGS.Global.and}{' '}
              <a href='https://www.gymleads.net/privacy' target='_blank' rel='noopener noreferrer'>{STRINGS.Signup.privacyPolicy}.</a>
            </p>
          </div>
        </div>
      </form>
    )
  }
}

SignupForm.propTypes = {
  data: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired
}

export default SignupForm
