import React from 'react'

export function generateNewFormState (oldState, name, value, isValid) {
  const fields = oldState.fields
  fields[name] = { ...fields[name], 'value': value, 'isValid': isValid }
  const newIsValidValue = fieldsAreValid(fields)
  return { ...oldState, 'fields': fields, 'isValid': newIsValidValue }
}

export function fieldsAreValid (fields) {
  let newIsValidValue = true
  Object.keys(fields).forEach(function (key) {
    const fieldIsValid = fields[key].isValid
    if (fieldIsValid === false) {
      newIsValidValue = false
    }
  })
  return newIsValidValue
}

export function safePropertyForObject (object, propertyName, defaultValue = null) {
  return (object && object[propertyName]) ? object[propertyName].toString() : defaultValue
}

export function safeRawPropertyForObject (object, propertyName, transformPropertyFunc, defaultValue = null) {
  let value = defaultValue
  if (object && object[propertyName] !== null && object[propertyName] !== undefined) {
    value = object[propertyName]
  }

  if (value && transformPropertyFunc) {
    return transformPropertyFunc(value)
  }

  return value
}

export function safeBooleanPropertyForObject (object, propertyName, defaultValue = false) {
  const rawObject = safeRawPropertyForObject(object, propertyName)
  if (rawObject === undefined || rawObject === null) {
    return defaultValue
  }
  return rawObject
}

export function newPayloadForFormValue (payload, formField, key) {
  if (formField && formField.value) {
    return { ...payload, [key]: formField.value }
  } else if (formField && formField.value === false) {
    return { ...payload, [key]: formField.value }
  } else if (formField && formField.value === '') {
    return { ...payload, [key]: null }
  } else {
    return payload
  }
}

export function newPayloadForValue (payload, value, key) {
  if (value !== null && value !== undefined && value !== '') {
    return { ...payload, [key]: value }
  } else {
    return payload
  }
}

export const errorPanelForErrors = (errors, showKey) => {
  return (
    <div className='alert alert-danger' role='alert' hidden={!errors}>
      <ul>
        {
          errors && Object.keys(errors).map(function (key) {
            if (key === 'param') { return null }
            const stringValue = (Array.isArray(errors[key])) ? errors[key][0] : errors[key]
            const errorString = (showKey) ? key + ' ' + stringValue : stringValue
            return <li key={key}>{errorString.charAt(0).toUpperCase() + errorString.slice(1)}</li>
          })
        }
      </ul>
    </div>
  )
}

export const errorPanelForErrorStrings = (errorStrings) => {
  return (
    <div className='alert alert-danger' role='alert' hidden={!errorStrings || errorStrings.length === 0}>
      <ul>
        {
          errorStrings && errorStrings.map(function (errorString, index) {
            return <li key={index}>{errorString.charAt(0).toUpperCase() + errorString.slice(1)}</li>
          })
        }
      </ul>
    </div>
  )
}

export function generateFieldsForKeys (object, keys, payloadKeys, validator, defaultValue) {
  let fields = {}
  keys.forEach((key) => {
    const value = safePropertyForObject(object, payloadKeys[key], defaultValue)
    let isValid = true
    if (validator && validator[key]) {
      const validations = validator[key]
      validations.forEach(function (validation) {
        isValid = validation.rule(value)
      })
    }
    const field = {
      value: value,
      isValid: isValid,
      key: payloadKeys[key]
    }
    fields = { ...fields, [key]: field }
  })

  return fields
}

export function formDataFromData (data, fieldNames) {
  const fields = data.fields

  let formData = {}
  fieldNames.forEach((fieldName) => {
    const field = fields[fieldName]
    const key = field.key
    const value = field.value
    formData = { ...formData, [key]: value }
  })

  return formData
}
