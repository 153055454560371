import PropTypes from "prop-types";
import React, { Component } from "react";
import STRINGS from "strings";
import { validator } from "../validator";
import { generateNewFormState, errorPanelForErrors } from "utils/formHelpers";
import { FormFields } from "modules/components";

const { TextField } = FormFields;

export class CreateCompanyFormView extends Component {
  handleInput = (fieldName, value, isValid) => {
    const { data, updateData } = this.props;
    const newData = generateNewFormState(data, fieldName, value, isValid);
    updateData(newData);
  };

  formatValueForMailDomain(value) {
    if (value === null) {
      return "";
    }
    return value
      .trim()
      .replace(/\s+/g, "-")
      .replace(/,/g, "")
      .replace(/'/g, "")
      .replace(/\./g, "")
      .replace(/\(/g, "")
      .replace(/\)/g, "")
      .replace(/&/g, "and")
      .replace(/'/g, "")
      .replace(/"/g, "")
      .replace(/\+/g, "and")
      .toLowerCase();
  }

  handleMailDomainInput = (fieldName, value, isValid) => {
    let updatedValue = this.formatValueForMailDomain(value);
    this.handleInput(fieldName, updatedValue, isValid);
  };

  render() {
    const {
      title,
      url,
      userDisplayName,
      desiredMailDomainName,
    } = this.props.data.fields;
    const { errors } = this.props;
    let mailDomainPlaceholder = STRINGS.CreateCompany.enterCompanyName;
    if (title.value !== null) {
      mailDomainPlaceholder = this.formatValueForMailDomain(title.value);
    }
    return (
      <div>
        {errorPanelForErrors(errors)}
        <form className="form-horizontal">
          <div className="row">
            <div className="col-md-12">
              <TextField
                name="title"
                label={STRINGS.CreateCompany.companyNameLabel}
                placeholder={STRINGS.CreateCompany.companyNamePlaceholder}
                onChange={this.handleInput}
                validator={validator}
                value={title.value}
                usePadding
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TextField
                name="userDisplayName"
                label={STRINGS.CreateCompany.brandNameLabel}
                placeholder={STRINGS.CreateCompany.brandNamePlaceholder}
                onChange={this.handleInput}
                value={userDisplayName.value}
                usePadding
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TextField
                name="url"
                label={STRINGS.CreateCompany.websiteUrlLabel}
                placeholder={STRINGS.CreateCompany.websiteUrlPlaceholder}
                onChange={this.handleInput}
                validator={validator}
                value={url.value}
                usePadding
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TextField
                name="desiredMailDomainName"
                label={STRINGS.CreateCompany.mailDomainLabel}
                placeholder={mailDomainPlaceholder}
                onChange={this.handleMailDomainInput}
                value={desiredMailDomainName.value}
                formGroupIconElement={".gymleads-email.net"}
                usePadding
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

CreateCompanyFormView.propTypes = {
  data: PropTypes.object.isRequired,
  errors: PropTypes.object,
  updateData: PropTypes.func.isRequired,
};

export default CreateCompanyFormView;
