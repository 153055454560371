import STRINGS from 'strings'

export const NAME = 'steps'

export const SET_COMPANY_STEP = {
  id: NAME + '/SET_COMPANY_STEP',
  icon: 'fa fa-map',
  title: STRINGS.Steps.setUpCompany
}
export const SET_LOCATION_STEP = {
  id: NAME + '/SET_LOCATION_STEP',
  icon: 'fa fa-building',
  title: STRINGS.Steps.setUpLocation
}
export const SET_PAYMENT_STEP = {
  id: NAME + '/SET_PAYMENT_STEP',
  icon: 'fa fa-credit-card',
  title: STRINGS.Steps.paymentDetails
}
