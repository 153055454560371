import PropTypes from "prop-types";
import React, { Component } from "react";
import STRINGS from "strings";
import { FormFields } from "modules/components";
import { validator } from "../validator";
import { generateNewFormState, errorPanelForErrors } from "utils/formHelpers";

import Cards from "react-credit-cards";
import "react-credit-cards/lib/styles-compiled.css";
const { TextField } = FormFields;

export class SetupPaymentFormView extends Component {
  state = {
    currentFieldName: null,
  };

  handleInput = (fieldName, value, isValid) => {
    const { data, updateData } = this.props;
    const newData = generateNewFormState(data, fieldName, value, isValid);
    updateData(newData);
  };

  onFocus = (fieldName) => {
    this.setState({ ...this.state, currentFieldName: fieldName });
  };

  render() {
    const { number, name, expire, cvc } = this.props.data.fields;
    const { errors } = this.props;
    return (
      <div>
        {errorPanelForErrors(errors, false)}
        <div className="row">
          <div className="col-sm-6 hidden-xs">
            <Cards
              number={number.value}
              expiry={expire.value}
              cvc={cvc.value}
              name={name.value}
              focused={this.state.currentFieldName}
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xs-12">
            <TextField
              name="number"
              label={STRINGS.SetupPayment.cardNumberLabel}
              placeholder="**** **** **** ****"
              formFieldStyle=""
              onChange={this.handleInput}
              disabled={false}
              onFocus={this.onFocus}
              validator={validator}
              initialValue={number.value}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <TextField
              name="name"
              label={STRINGS.SetupPayment.fullNameLabel}
              placeholder={STRINGS.SetupPayment.fullNamePlaceholder}
              formFieldStyle=""
              onChange={this.handleInput}
              disabled={false}
              onFocus={this.onFocus}
              validator={validator}
              initialValue={name.value}
            />
          </div>
        </div>
        <div className="row push-30">
          <div className="col-xs-6">
            <TextField
              name="expire"
              label={STRINGS.SetupPayment.expiryLabel}
              placeholder="MM/YY"
              formFieldStyle=""
              onChange={this.handleInput}
              disabled={false}
              onFocus={this.onFocus}
              validator={validator}
              initialValue={expire.value}
            />
          </div>
          <div className="col-xs-6">
            <TextField
              name="cvc"
              label={STRINGS.SetupPayment.cvcLabel}
              placeholder="***"
              formFieldStyle=""
              onChange={this.handleInput}
              disabled={false}
              onFocus={this.onFocus}
              validator={validator}
              initialValue={cvc.value}
            />
          </div>
        </div>
      </div>
    );
  }
}

SetupPaymentFormView.propTypes = {
  updateData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object,
};

export default SetupPaymentFormView;
