import React, { Component } from 'react'
import { PrivateRouteContainer, Intercom } from 'modules/components'
import './CoreLayout.css'
import 'styles/core.css'
import 'styles/oneui.css'

import { Switch, Route } from 'react-router-dom'
import signup from 'containers/signup'

import secure from 'containers/secure'
import sso from 'containers/sso'

export class AppView extends Component {

  render () {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={signup.SignupContainer} />
          <Route exact path={'/' + sso.constants.NAME + '/:token'} component={sso.SsoContainer} />
          <PrivateRouteContainer path="/secure" component={secure.SecureContainer} />
        </Switch>
        <Intercom />
      </div>
    )
  }
}

AppView.propTypes = {}

export default AppView
