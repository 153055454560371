import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import CreateLocationView from '../components/CreateLocationView'

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    data: selectors.getData(state),
    location: selectors.getLocation(state),
    countries: selectors.getCountries(state),
    loading: selectors.getLoading(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateLocation: actions.updateLocation,
    getLocation: actions.getLocation,
    getCountries: actions.getCountries
  },
    dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateLocationView))
