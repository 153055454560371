import { request, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

function startLoading () {
  return {
    type: actionTypes.LOADING_STARTED
  }
}

function stopLoading () {
  return {
    type: actionTypes.LOADING_STOPPED
  }
}

export function loadInitialStateForCurrentUser () {
  return dispatch => Promise.all([
    dispatch(startLoading()),
    dispatch(getUserCompanyDetails()),
    dispatch(getAvailableLocations()),
    dispatch(getUserRoles()),
  ]).then(() => {
    dispatch(stopLoading())
  })
}

// GET WHITELABEL PROFILE
export const fetchWhitelabelProfileLogo = (clientID) => {
  return dispatch => {
    function onFailure (errors) { }

    function onSuccess (payload) {
      dispatch(getWhitelabelProfileSuccess(payload))
    }

    const url = `whitelabel_profile_logo?client=${clientID}`
    return request(url, GET, null, onSuccess, onFailure)
  }
}

export function getWhitelabelProfileSuccess (whitelabelProfile) {
  return {
    type: actionTypes.WHITELABEL_PROFILE,
    whitelabelProfile
  }
}

// GET USER COMPANY
const getUserCompanyDetails = () => {
  return dispatch => {
    function onFailure (errors) { }

    function onSuccess (payload) {
      if (payload.company) {
        const company = payload.company
        dispatch(getUserCompanySuccess(company))
      }
    }

    return request('user_company', GET, null, onSuccess, onFailure)
  }
}

export function getUserCompanySuccess (company) {
  return {
    type: actionTypes.COMPANY_DATA_FETCH_SUCESS,
    company
  }
}

// GET LOCATIONS
export const getAvailableLocations = (success) => {
  return dispatch => {
    function onFailure (errors) { }

    function onSuccess (payload) {
      const locations = payload.locations
      if (locations) {
        dispatch(getLocationsSuccess(locations))
      }
      if (success) {
        dispatch(success())
      }
    }

    return request('locations', GET, null, onSuccess, onFailure)
  }
}

function getLocationsSuccess (locations) {
  return {
    type: actionTypes.LOCATIONS_FETCH_SUCCESS,
    locations
  }
}

// GET User Roles Funnel
const getUserRoles = () => {
  return dispatch => {
    function onFailure (errors) { }

    function onSuccess (payload) {
      dispatch(getUserRolesSuccess(payload.user_roles))
    }

    return request('user_roles', GET, null, onSuccess, onFailure)
  }
}

function getUserRolesSuccess (userRoles) {
  return {
    type: actionTypes.USER_ROLES_FETCH_SUCCESS,
    userRoles
  }
}