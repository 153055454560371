import config from 'config'
import store from 'store/store'

import session from 'modules/stores/session'

// START USER SESSIONS
export function startUserSession () {
  const state = store.getState()
  const user = session.selectors.getUser(state)

  let intercomhash = session.selectors.getIntercomHash(state)
  if (intercomhash) {
    intercomhash = intercomhash.replace(/['"]+/g, '')
  }

  if (config.debug) {
    console.log('ANALYTIC START', user, null, intercomhash)
    return
  }

  const options = {
    app_id: config.intercom_app_id,
    email: user.email,
    user_id: user.id,
    username: user.username,
    name: user.display_name,
    role: user.user_role.name,
    created_at: user.created_at,
    user_hash: intercomhash
  }

  if (typeof window.Bugsnag !== 'undefined') {
    window.Bugsnag.user = {
      id: user.id,
      name: user.display_name
    }
  }

  try {
    if (window.Intercom) {
      window.Intercom('boot', options)
    }
  } catch (exception) {
    if (typeof window.Bugsnag !== 'undefined') {
      window.Bugsnag.notifyException(exception)
    }
  }
}

// STOP USER SESSIONS
export function stopUserSession () {
  if (config.debug) {
    console.log('ANALYTIC STOP')
    return
  }
  if (window.Intercom) {
    window.Intercom('shutdown')
  }
  trackEvent('Log Out')
  if (typeof window.Bugsnag !== 'undefined') {
    window.Bugsnag.user = null
  }
}

// EVENT TRACKING
export function trackEvent (event, data) {
  if (config.debug) {
    console.log('ANALYTIC EVENT', event, data)
    return
  }
  sendMixpanelEvent(event, data)
  sendIntercomEvent(event, data)
}

function sendMixpanelEvent (event, data) {
  if (!window.mixpanel) { return }
  if (data) {
    window.mixpanel.track(event, data)
  } else {
    window.mixpanel.track(event)
  }
}

function sendIntercomEvent (event, data) {
  if (!window.Intercom) { return }
  if (data) {
    window.Intercom('trackEvent', event, data)
  } else {
    window.Intercom('trackEvent', event)
  }
}
