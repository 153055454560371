import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'

import SetupPaymentView from '../components/SetupPaymentView'

import * as selectors from '../selectors'
import * as actions from '../actions'

function mapStateToProps (state) {
  return {
    data: selectors.getData(state),
    paymentMethod: selectors.getPaymentMethod(state),
    loading: selectors.getLoading(state)
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    createPaymentMethod: actions.createPaymentMethod,
    getPaymentMethod: actions.getPaymentMethod,
    completeSetup: () => push('/secure/complete')
  },
    dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SetupPaymentView))
