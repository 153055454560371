import { isEmail, isPresent, isMinLength, hasWhiteSpace, isTrue } from 'utils/validation'
import STRINGS from 'strings'

const passwordMinLength = 4
export const validator = {
  givenName: [{
    rule: isPresent,
    errorMessage: STRINGS.Signup.Validator.missingGivenName
  }],
  surname: [{
    rule: isPresent,
    errorMessage: STRINGS.Signup.Validator.missingLastName
  }],
  email: [{
    rule: isEmail,
    errorMessage: STRINGS.Signup.Validator.badEmailAddress
  }],
  username: [{
    rule: isMinLength(passwordMinLength),
    errorMessage: `${STRINGS.Signup.Validator.usernameLength1} ${passwordMinLength} ${STRINGS.Signup.Validator.usernameLength2}`
  }, {
    rule: hasWhiteSpace,
    errorMessage: STRINGS.Signup.Validator.usernameSpaces
  }, {
    rule: isPresent,
    errorMessage: STRINGS.Signup.Validator.usernameMissing
  }],
  agreeToTerms: [{
    rule: isTrue,
    errorMessage: STRINGS.Signup.Validator.aggreeToTerms
  }],
  password: [{
    rule: isMinLength(passwordMinLength),
    errorMessage: `${STRINGS.Signup.Validator.passwordLength1} ${passwordMinLength} ${STRINGS.Signup.Validator.passwordLength2}`
  }, {
    rule: isPresent,
    errorMessage: STRINGS.Signup.Validator.passwordMissing
  }]
}
