import * as actionTypes from './actionTypes'

export function setStepIndex (stepIndex) {
  return {
    type: actionTypes.SET_STEP_INDEX,
    currentStepIndex: stepIndex
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
