import moment from 'moment'
import 'moment-timezone'

import * as actionTypes from './actionTypes'
import { generateFieldsForKeys, fieldsAreValid, generateNewFormState } from 'utils/formHelpers'
import { fieldNames } from './constants'
import { validator } from './validator'

const SETUP_LOCATION_ACTION_HANDLERS = {
  [actionTypes.UPDATE_DATA]: (state, action) => {
    return { ...state, data: action.data }
  },
  [actionTypes.SET_LOADING]: (state, action) => {
    return { ...state, loading: action.loading }
  },
  [actionTypes.SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.errors }
  },
  [actionTypes.SET_LOCATION]: (state, action) => {
    return { ...state, location: action.location }
  },
  [actionTypes.PREFIL_FORM]: (state, action) => {
    const fields = generateFieldsForKeys(action.location, fieldNames, payloadKeys, validator)
    let newData = {
      fields: fields,
      isValid: fieldsAreValid(fields)
    }
    if (!action.location) {
      newData = generateNewFormState(newData, 'timezone', moment.tz.guess(), true)
    }
    return { ...state, data: newData }
  },
  [actionTypes.SET_COUNTRIES]: (state, action) => {
    return { ...state, countries: action.countries }
  },
  [actionTypes.RESET]: (state, action) => {
    return initialState()
  },
}

const payloadKeys = {
  name: 'name',
  addressLineOne: 'address_line_one',
  addressLineTwo: 'address_line_two',
  city: 'city',
  state: 'state',
  phoneNumber: 'phone_number',
  countryId: 'country_id',
  zipPostcode: 'zip_postcode',
  timezone: 'timezone',
}

function initialState (location) {
  const fields = generateFieldsForKeys(location, fieldNames, payloadKeys, validator)
  return {
    loading: false,
    location: location,
    countries: [],
    errors: null,
    data: {
      fields: fields,
      isValid: fieldsAreValid(fields)
    }
  }
}

export default function (state = initialState(), action) {
  const handler = SETUP_LOCATION_ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
