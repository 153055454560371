import { isPresent, isMinLength } from 'utils/validation'
import STRINGS from 'strings'

export const validator = {
  name: [{
    rule: isPresent,
    errorMessage: STRINGS.CreateLocation.Validator.locationName
  }],
  addressLineOne: [{
    rule: isPresent,
    errorMessage: STRINGS.CreateLocation.Validator.addressOneRequired
  }],
  city: [{
    rule: isPresent,
    errorMessage: STRINGS.CreateLocation.Validator.cityRequired
  }],
  state: [{
    rule: isPresent,
    errorMessage: STRINGS.CreateLocation.Validator.stateRequired
  }],
  countryId: [{
    rule: isPresent,
    errorMessage: STRINGS.CreateLocation.Validator.countryRequired
  }],
  timezone: [{
    rule: isPresent,
    errorMessage: STRINGS.CreateLocation.Validator.whatTimezone
  }],
  zipPostcode: [{
    rule: isMinLength(3),
    errorMessage: STRINGS.CreateLocation.Validator.postcodeLength
  }, {
    rule: isPresent,
    errorMessage: STRINGS.CreateLocation.Validator.postcodeRequired
  }]
}
