import { isInteger, isMinLength, isMaxLength, isPresent } from 'utils/validation'
import STRINGS from 'strings'

function isCorrectExpiryFormat (value) {
  const correctLength = value.length === 5
  const includesSlash = value.includes('/')
  return correctLength && includesSlash
}

export const validator = {
  number: [{
    rule: isMinLength(14),
    errorMessage: STRINGS.SetupPayment.Validators.cardMinLength
  }, {
    rule: isMaxLength(19),
    errorMessage: STRINGS.SetupPayment.Validators.cardMaxLength
  }, {
    rule: isInteger,
    errorMessage: STRINGS.SetupPayment.Validators.cardNumbersOnly
  }],
  name: [{
    rule: isPresent,
    errorMessage: STRINGS.SetupPayment.Validators.cardName
  }],
  expire: [{
    rule: isPresent,
    errorMessage: STRINGS.SetupPayment.Validators.cardExpiry
  }, {
    rule: isCorrectExpiryFormat,
    errorMessage: STRINGS.SetupPayment.Validators.cardExpiryFormat
  }],
  cvc: [{
    rule: isMinLength(3),
    errorMessage: STRINGS.SetupPayment.Validators.cvcMinLength
  }, {
    rule: isMaxLength(4),
    errorMessage: STRINGS.SetupPayment.Validators.cvcMaxLength
  }, {
    rule: isInteger,
    errorMessage: STRINGS.SetupPayment.Validators.cvcNumbersOnly
  }]
}
