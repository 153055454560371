import { createSelector } from 'reselect'

// Locations
export const getLocations = state => state.userData.locations
export const getCurrentLocationId = state => state.userData.currentLocationId

export function locationForIdFactory (locationId) {
  return createSelector(
    [ getLocations ],
    (locations) => {
      if (locations && locations.length > 0) {
        return locationForId(locations, locationId)
      }
    }
  )
}

export const locationIds = createSelector(
  [ getLocations ],
  (locations) => {
    return locations.map(l => l.id)
  }
)

function locationForId (locations, locationId) {
  const index = locations.findIndex(location => parseInt((location.id), 10) === parseInt((locationId), 10))
  return locations[index]
}

// loading is complete

export const getCompany = state => state.userData.company
export const getMembership = state => state.userData.company.membership
export const getMembershipPermissions = state => state.userData.company.membership.membership_permission
export const getUserRoles = state => state.userData.userRoles

export const getWhitelabelProfile = state => state.userData.whitelabelProfile
// export const getWhitelabelProfileLogo = state => state.userData.whitelabelProfile.logo_url

export const getProductName = createSelector(
  [ getWhitelabelProfile ],
  (whitelabelProfile) => {
    if (whitelabelProfile && whitelabelProfile.name) {
      return whitelabelProfile.name
    } else {
      return 'Gymleads'
    }
  }
)