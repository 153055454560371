import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import signup from 'containers/signup'
import steps from 'containers/steps'
import createCompany from 'containers/createCompany'
import setupLocation from 'containers/setupLocation'
import setupPayment from 'containers/setupPayment'

import alert from 'modules/components/alert'

import stores from 'modules/stores'
const session = stores.session
const userData = stores.userData
const modal = stores.modal

export default function createReducer (asyncReducers) {
  return combineReducers({
    routing: routerReducer,
    [modal.constants.NAME]: modal.reducer,
    [alert.constants.NAME]: alert.reducer,
    [userData.constants.NAME]: userData.reducer,
    [session.constants.NAME]: session.reducer,
    [signup.constants.NAME]: signup.reducer,
    [steps.constants.NAME]: steps.reducer,
    [createCompany.constants.NAME]: createCompany.reducer,
    [setupLocation.constants.NAME]: setupLocation.reducer,
    [setupPayment.constants.NAME]: setupPayment.reducer,
    ...asyncReducers
  })
}
