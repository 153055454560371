import { request, POST, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'
import { formDataFromData } from 'utils/formHelpers'
import { fieldNames } from './constants'
import { push } from 'react-router-redux'

export const getPaymentMethod = () => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setPaymentMethod(payload.paychoice_payment_methods[0]))
    }

    return request('paychoice_payment_methods', GET, null, onSuccess, onFailure)
  }
}

export const createPaymentMethod = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    let paymentMethodBody = formDataFromData(data, fieldNames)
    const body = {
      paychoice_payment_method: paymentMethodBody
    }

    function onFailure (payload) {
      dispatch(setLoading(false))
      dispatch(setErrors(payload.errors))
    }

    function onSuccess (payload) {
      dispatch(setLoading(false))
      dispatch(setPaymentMethod(payload.paychoice_payment_methods))
      dispatch(push('/secure/complete'))
    }

    return request('paychoice_payment_methods', POST, body, onSuccess, onFailure)
  }
}

export function updateData (data) {
  return {
    type: actionTypes.UPDATE_DATA,
    data
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}

export function setErrors (errors) {
  return {
    type: actionTypes.SET_ERRORS,
    errors
  }
}

export function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setPaymentMethod(paymentMethod) {
  return {
    type: actionTypes.SET_PAYMENT_METHOD,
    paymentMethod
  }
}
