import React, { Component } from 'react'
import config from 'config'

export class Intercom extends Component {

  componentDidMount () {
    if (config.debug) { return }

    const options = { app_id: config.intercom_app_id }
    if (window.Intercom) {
      window.Intercom('boot', options)
    }
  }

  render () {
    return (
      <div />
    )
  }
}

export default Intercom
