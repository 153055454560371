export default {
  Global: {
    ok: "Ok",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
    done: "Done",
    create: "Create",
    update: "Update",
    delete: "Delete",
    name: "Name",
    number: "Mobile number",
    login: "Login",
    hey: "Hey",
    or: "or",
    and: "and",
    resetPassword: "Reset your password",
    copyrightDate: "2016-2022",
    copyrightText: "© Gymleads",
    poweredByGymleads: "© Powered by Gymleads",
    documentTitle: "The CRM for Gyms and fitness clubs",
  },
  Header: {
    accountSetup: "Account Setup",
    loggedInAs: "Logged in as",
    finishLaterBtn: "Finish later",
    finishLaterWarning:
      "Are you sure you want to stop setting up your account? You'll be able to come back and continue later",
  },
  AlertView: {
    areYouSure: "Are you sure?",
    imSure: "I'm sure",
  },
  Signup: {
    creatingAccount: "Creating Account",
    createAccount: "Create Account",
    createAnAccount: "Create an account",
    createYourAccount: "Create your account",
    enterDetails: "Enter your details to the left to get started with",
    signupDescription:
      "This will create a new account for your gym. Once you've created your account you will be able to invite your sales team, add more locations and start optimizing your sales.",

    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    username: "Username",
    password: "Password",
    iAgree: "I agree with the",
    termsAndConditions: "Terms And Conditions",
    privacyPolicy: "Privacy Policy",
    Validator: {
      missingGivenName: "We're missing your given name",
      missingLastName: "We're missing your last name",
      badEmailAddress: "That doesn't seem to be an email address",
      usernameLength1: "Your username needs to be",
      usernameLength2: "characters or more",
      usernameSpaces: "Usernames should not have any spaces",
      usernameMissing: "Looks like your username is missing",
      aggreeToTerms:
        "You must agree to the terms and conditions before you can create an account",
      passwordLength1: "Your password needs to be longer than",
      passwordLength2: "characters",
      passwordMissing: "Looks like the password is missing",
    },
  },
  CreateCompany: {
    updateCompany: "Update Company",
    setUpCompany: "Set Up Company",
    companyNameLabel: "Company Name",
    companyNamePlaceholder: "Your offical, unique company name.",
    brandNameLabel: "Your brand name",
    brandNamePlaceholder: "Leave blank to use the company name.",
    websiteUrlLabel: "Your URL/Website",
    websiteUrlPlaceholder: "Your website",
    mailDomainLabel: "Your Desired Mail Domain",
    enterCompanyName: "Enter a company name",
    Validator: {
      missingCompanyName: "We need to know what your company is called.",
      invalidUrl: "That doesn't look like a URL.",
      companyWebsite: "What is your companys website?",
    },
    HelperText: {
      intro:
        "Let's get you set up! Can you tell us about your company or branding?",
      para1:
        "Your company name is a unique way of identifying your company and account (eg Happy Gyms PTY LTD). You can set a more brand friendly name to come across a bit less formal if you'd like (eg Happy Gyms).",
      para2:
        "We use these details when communicating with your customers through websites, email, etc.",
      para3:
        "If you have any questions or are experiencing any issues, don't hesitate to send us a message using the chat box in the bottom right hand corner.",
      para4: "Remember, these values will be used for all of your locations.",
    },
  },
  Steps: {
    setUpCompany: "Set Up Company",
    setUpLocation: "Set Up 1st location",
    paymentDetails: "Payment Details",
  },
  CreateLocation: {
    updateLocation: "Update location",
    createLocation: "Create location",
    setupFirstLocation: "Setup first location",
    nameLabel: "Club name",
    addressLineOneLabel: "Address Line One",
    addressLineTwoLabel: "Address Line Two",
    phoneNumberLabel: "Phone Number",
    cityLabel: "City",
    stateLabel: "State",
    countryLabel: "Country",
    countryPlaceholder: "Select a country",
    postcodeLabel: "ZIP/Postcode",
    timezoneLabel: "Time Zone",
    timezonePlaceholder: "Select the timezone this location operates in",
    HelperText: {
      intro: "Let's add a location next.",
      para1:
        "All of your leads and users will be managed through different 'locations'. Your account can have multiple locations.",
      para2Part1: "You need at least one location to use",
      para2Part2: "You'll be able to add more after you finish the setup.",
    },
    Validator: {
      locationName: "What should we call this location?",
      addressOneRequired: "Address Line One is required",
      cityRequired: "City is required",
      stateRequired: "State is required",
      countryRequired: "Country is required",
      whatTimezone: "What timezone does the location operate in?",
      postcodeLength: "Zip or Postcode must 3 or more characters",
      postcodeRequired: "Zip/Postcode is required",
    },
  },
  SetupPayment: {
    setupPaymentDetails: "Set up payment details",
    setPaymentMethod: "Set Payment Method",
    paymentMethodAlreadySet:
      "You've already set a payment method for this account",
    completeSetupBtn: "Complete Setup",
    cardNumberLabel: "Card Number",
    fullNameLabel: "Full Name",
    fullNamePlaceholder: "Full Name",
    expiryLabel: "Expiry",
    cvcLabel: "CVC",
    HelperText: {
      intro: "Finally, let's add your payment details.",
      para1:
        "Your payment method will be used for processing your monthly subscription payment and SMS credit. You can set up different cards for differnt clubs after you've set up your account.",
      para2Part1: "For more details about how our pricing works, check out our",
      para2Part2: "knowledge base article.",
      paymentSecurity:
        "All payment details are sent on a 256 bit encrypted connection. No credit card details are stored on our servers and are managed by a trusted, PCI compliant 3rd party.",
    },
    Validators: {
      cardMinLength:
        "A Credit Card number must be at least 16 numbers (no spaces please)",
      cardMaxLength: "The Credit Card must be at most 19 characters",
      cardNumbersOnly:
        "Credit Card numbers should only be numbers (no spaces please)",
      cardName: "What is the name on the card?",
      cardExpiry: "When does the card expire?",
      cardExpiryFormat:
        "Expiry needs to be in the format 'mm/yy' (make sure to include the /)",
      cvcMinLength: "The CVC must be at least 3 numbers",
      cvcMaxLength: "The CVC must be at most 4 characters",
      cvcNumbersOnly: "CVC's can only be numbers",
    },
  },
  Complete: {
    goToDashboardBtn: "",
    finishedSetup: "You've finished setting up your account",
    headToDashboard: "Head to the dashboard to get started.",
  },
};
