import STRINGS from 'strings'
export const defaultConfig = {
  showCancel: true,
  cancelButtonStyle: 'default',
  confirmButtonText: STRINGS.AlertView.imSure,
  style: 'danger',
  title: STRINGS.AlertView.areYouSure
}

export default defaultConfig
