import PropTypes from "prop-types";
import React, { Component } from "react";
import STRINGS from "strings";
import { validator } from "../validator";
import { generateNewFormState, errorPanelForErrors } from "utils/formHelpers";
import { FormFields } from "modules/components";
import { placeholdersForCountry } from "modules/helpers/FormatHelpers";

import moment from "moment";
import "moment-timezone";

const { TextField, SelectField } = FormFields;

export class CreateCompanyFormView extends Component {
  handleInput = (fieldName, value, isValid) => {
    const { data, updateData } = this.props;
    const newData = generateNewFormState(data, fieldName, value, isValid);
    updateData(newData);
  };

  phonePlaceholder = () => {
    const { data, countries } = this.props;
    const countryId = data.fields.countryId;
    return placeholdersForCountry(countryId.value, countries).phone;
  };

  render() {
    const {
      name,
      addressLineOne,
      addressLineTwo,
      city,
      state,
      timezone,
      phoneNumber,
      countryId,
      zipPostcode,
    } = this.props.data.fields;
    const { countries, errors } = this.props;
    return (
      <div>
        {errorPanelForErrors(errors, true)}
        <TextField
          name="name"
          label={STRINGS.CreateLocation.nameLabel}
          placeholder="Melbourne Central"
          onChange={this.handleInput}
          value={name.value}
        />
        <TextField
          name="addressLineOne"
          label={STRINGS.CreateLocation.addressLineOneLabel}
          placeholder="Level 5"
          onChange={this.handleInput}
          validator={validator}
          value={addressLineOne.value}
        />
        <TextField
          name="addressLineTwo"
          label={STRINGS.CreateLocation.addressLineTwoLabel}
          placeholder="12 Fitness Cresent"
          onChange={this.handleInput}
          value={addressLineTwo.value}
        />
        <TextField
          name="phoneNumber"
          label={STRINGS.CreateLocation.phoneNumberLabel}
          placeholder={this.phonePlaceholder()}
          onChange={this.handleInput}
          value={phoneNumber.value}
        />
        <div className="row">
          <div className="col-sm-6">
            <TextField
              name="city"
              label={STRINGS.CreateLocation.cityLabel}
              placeholder="Glen Iris"
              onChange={this.handleInput}
              validator={validator}
              value={city.value}
            />
          </div>
          <div className="col-sm-6">
            <TextField
              name="state"
              label={STRINGS.CreateLocation.stateLabel}
              placeholder="VIC"
              onChange={this.handleInput}
              validator={validator}
              value={state.value}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <SelectField
              name="countryId"
              label={STRINGS.CreateLocation.countryLabel}
              possibleOptions={countries}
              placeHolderText={STRINGS.CreateLocation.countryPlaceholder}
              onChange={this.handleInput}
              validator={validator}
              displayFieldName={"name"}
              initialValue={countryId.value}
            />
          </div>
          <div className="col-sm-6">
            <TextField
              name="zipPostcode"
              label={STRINGS.CreateLocation.postcodeLabel}
              placeholder="3000"
              onChange={this.handleInput}
              validator={validator}
              value={zipPostcode.value}
            />
          </div>
          <div className="col-sm-6">
            <SelectField
              name="timezone"
              label={STRINGS.CreateLocation.timezoneLabel}
              possibleOptions={moment.tz.names()}
              placeHolderText={STRINGS.CreateLocation.timezonePlaceholder}
              formFieldStyle=""
              validator={validator}
              onChange={this.handleInput}
              initialValue={timezone.value}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateCompanyFormView.propTypes = {
  data: PropTypes.object.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.object,
  updateData: PropTypes.func.isRequired,
};

export default CreateCompanyFormView;
